<template>
  <div class="ticket-details">
    <h2>Ticket Details</h2>
    <div class="ticket-info">
      <span><strong>Department : </strong>{{ ticket.department.name }}</span>
      <span><strong>Title : </strong> {{ ticket.title }}</span>
      <span><strong>Requestor : </strong> {{ ticket.name }}</span>
      <span
        ><strong>Status : </strong> {{ getStatusLabel(ticket.status) }}</span
      >
      <span><strong>Assign To : </strong> {{ ticket.staff.name }}</span>
      <span><strong>Last Reply : </strong> {{ ticket.last_reply }}</span>
      <div style="height: 100px; width: 100%; background-color: beige; border-radius: 5px; padding: 10px; margin-top: 10px;margin-bottom: 10px;">
        <strong>Body : </strong>
        {{ ticket.message }}
      </div>
    </div>
    <div class="ticket-info">
      <span
        ><strong>Change Department: </strong>
        <b-form-select
          v-model="selectedDepartment"
          @change="updateDepartment"
          style="width: 250px"
        >
          <option v-for="item in departments" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </b-form-select>
      </span>
      <span
        ><strong>Assigned to : </strong>
        <b-form-select
          v-model="selectedStaff"
          @change="updateStaff"
          style="width: 150px"
        >
          <option v-for="staff in staffs" :key="staff.id" :value="staff.id">
            {{ staff.name }}
          </option>
        </b-form-select>
      </span>
      <span
        ><strong>Set Priority : </strong>
        <b-form-select
          v-model="selectedUrgency"
          @change="updateUrgency"
          style="width: 150px"
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </b-form-select>
      </span>
      <span
        ><strong>Change Status : </strong>
        <b-form-select
          v-model="selectedStatus"
          @change="updateStatus"
          style="width: 150px"
        >
          <option value="open">Open</option>
          <option value="in_progress">In Progress</option>
          <option value="resolved">Resolved</option>
          <option value="closed">Closed</option>
        </b-form-select>
      </span>
    </div>

    <div class="reply-section">
      <h3>Reply</h3>
      <b-form @submit.prevent="submitReply">
        <b-form-textarea
          v-model="replyMessage"
          placeholder="Type your reply here..."
          rows="3"
          required
        ></b-form-textarea>
        <b-button
          type="submit"
          variant="primary"
          style="margin-top: 10px; margin-bottom: 10px"
          >Add Reply</b-button
        >
      </b-form>
    </div>

    <div class="previous-replies">
      <h4>Previous Replies :</h4>
      <table class="table table-bordered table-hover">
        <thead class="bg-primary text-purple">
          <tr>
            <th class="text-center">SL</th>
            <th class="text-center">Staff</th>
            <th class="text-center">Message</th>
            <th class="text-center">Time</th>
            <!-- <th class="text-center">Action</th> -->
            <!-- New Action Column -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(reply, index) in replies"
            :key="index"
            class="bg-gray-100"
          >
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ ticket.staff.name }}</td>
            <td class="text-center">{{ reply.message }}</td>
            <td class="text-center">{{ reply.created_at }}</td>
            <!-- <td class="text-center">
              <button class="btn btn-danger" @click="deleteReply(reply.id)">
                Delete
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { BFormSelect, BFormTextarea, BButton, BForm } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  GET_TICKET_REPLIES,
  GET_TICKET_DETAILS,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_DEPARTMENT,
  UPDATE_TICKET_STAFF,
  UPDATE_TICKET_URGENCY,
  ADD_TICKET_REPLY,
  GET_DEPARTMENTS,
  GET_STAFFS,
} from "@core/services/api";

export default {
  components: {
    BFormSelect,
    BFormTextarea,
    BButton,
    BForm,

  },
  data() {
    return {
      ticket: null,
      replies: [],
      department_id: "",
      ticketId: "",
      name: "",
      title: "",
      body: "",
      last_reply: "",
      message: "",
      replyMessage: "",
      selectedStatus: "",
      selectedDepartment: "",
      selectedStaff: "",
      selectedUrgency: "",
      status: "",
      urgency: "",
      staff_id: "",
      departments: [],
      staffs: [],
      department: "",
      staff: "",
      isp_id: JSON.parse(localStorage.getItem("userData")),
      ticket_id: this.$route.params.id,
    };
  },
  methods: {
    fetchDepartments() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_DEPARTMENTS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          console.log("data:", res.data);
          console.log("isp:", this.isp_id);
          this.departments = res.data.data.departments;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStaffs() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_STAFFS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          console.log("data:", res.data);
          console.log("isp:", this.isp_id);
          this.staffs = res.data.data.staffs;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchTicketDetails() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_TICKET_DETAILS, {
          params: {
            ticketId: this.ticket_id, // Change this if you're passing ticketId dynamically
          },
        })
        .then((res) => {
          console.log(res.data);
          this.ticket = res.data.data.ticket;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusLabel(status) {
      switch (status) {
        case "open":
          return "Open";
        case "in_progress":
          return "In Progress";
        case "resolved":
          return "Resolved";
        case "closed":
          return "Closed";
        default:
          return "Unknown";
      }
    },
    fetchTicketReplies() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_TICKET_REPLIES, {
          params: {
            ticketId: this.ticket_id, // Change this if you're passing ticketId dynamically
          },
        })
        .then((res) => {
          console.log(res.data);
          this.replies = res.data.data.replies;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDepartment() {
      console.log(this.selectedDepartment);
      this.$http
        .post(process.env.VUE_APP_BASEURL + UPDATE_TICKET_DEPARTMENT, {
            ticketId: this.ticket_id,
            department_id: this.selectedDepartment,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket Department updated successfully!",
              variant: "success",
              icon: 'fas fa-check-circle',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update Department.",
              variant: "danger",
              icon: 'fas fa-times-circle'
            },
          });
        });
    },
    updateUrgency() {
      console.log(this.selectedUrgency);
      this.$http
        .post(process.env.VUE_APP_BASEURL + UPDATE_TICKET_URGENCY, {
          ticketId: this.ticket_id,
          urgency: this.selectedUrgency,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket Urgency updated successfully!",
              variant: "success",
              icon: 'fas fa-check-circle',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update Urgency.",
              variant: "danger",
              icon: 'fas fa-times-circle'
            },
          });
        });
    },
    updateStatus() {
      console.log(this.selectedStatus);
      this.$http
        .post(process.env.VUE_APP_BASEURL + UPDATE_TICKET_STATUS, {
          ticketId: this.ticket_id,
          status: this.selectedStatus,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket status updated successfully!",
              variant: "success",
              icon: 'fas fa-check-circle',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update status.",
              variant: "danger",
              icon: 'fas fa-times-circle'
            },
          });
        });
    },
    updateStaff() {
      console.log(this.selectedStaff);
      this.$http
        .post(process.env.VUE_APP_BASEURL + UPDATE_TICKET_STAFF, {
            ticketId: this.ticket_id,
            staff_id: this.selectedStaff,
           
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket staff updated successfully!",
              variant: "success",
              icon: 'fas fa-check-circle',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update staff.",
              variant: "danger",
              icon: 'fas fa-times-circle'
            },
          });
        });
    },
    submitReply() {
      console.log(this.ticket.staff.id);
      console.log(this.replyMessage);
      this.$http
        .post(process.env.VUE_APP_BASEURL + ADD_TICKET_REPLY, {
            ticketId: this.ticket_id,
            staff_id: this.ticket.staff.id,
            message: this.replyMessage,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Reply sent successfully!",
              variant: "success",
              icon: 'fas fa-check-circle',
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to send reply.",
              variant: "danger",
              icon: 'fas fa-times-circle'
            },
          });
        });
      this.replyMessage = ""; // Clear the reply message
      this.fetchTicketReplies();; // Refresh ticket details to show new reply
    },
    deleteStaff(replyId) {
      if (confirm("Are you sure you want to delete this Staff?")) {
        this.$http
          .post(process.env.VUE_APP_BASEURL + DELETE_STAFF, {
            reply_id: replyId,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Staff deleted successfully!",
                variant: "success",
              },
            });
            // Refetch the replys list after deletion
            this.fetchStaffs();
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to delete the Staff.",
                message: "Failed to delete the Staff.",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  mounted() {
    this.fetchTicketDetails();
    this.fetchTicketReplies();
    this.fetchStaffs();
    this.fetchDepartments();
  },
};
</script>
<style>
span {
  color: darkblue;
  padding-right: 30px;
}
</style>
